import React from "react";
import "./styles.css"
function SharePercentage(props) {
    const handleChange = (event) => {
      props.handleChange(event.target.name,event.target.value)
    }
  return (
    <>
      <div className="p-2 flex flex-col">
        <label htmlFor="nomineePercentage" className="font-semibold">
          Nominee Percentage*
        </label>
        <input
          type="number"
          id="nomineePercentage"
          onWheel={(e) => e.target.blur()}
          // placeholder="Enter Percentage"
          name="sharePercentage"
          value={props.nominee.sharePercentage}
          onChange={handleChange}
          // className="mt-2 p-2 bg-red border border-white rounded-xl text-white"
          className="mt-2 p-2 bg-white rounded-xl text-black no-spinners"
        />
      </div>
    </>
  );
}

export default SharePercentage;
