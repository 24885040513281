import React from "react";

function TypeOfDocument({ handleChange, nominee }) {
  return (
    <div className="flex flex-col" key="typeOfDocument">
      <span className="text-white font-bold">Type Of Identity Document</span>
      <select
        className="text-black py-2 rounded-xl px-2 mt-3"
        name="typeOfDocument"
        value={nominee.typeOfDocument || ""}
        onChange={(event) => handleChange("typeOfDocument", event.target.value)}
      >
        <option value="" disabled>
          Type Of Identity Document
        </option>
        {[
          "AadharCard",
          "PanCard",
          "Driving Licence",
          "Voter Id",
          "Passport Number",
        ].map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      {/* Error handling */}
      {false && (
        <p className="text-white font-thin">
          ({false ? "Type Of Identity Document is Required" : ""}*)
        </p>
      )}
    </div>
  );
}

export default TypeOfDocument;
