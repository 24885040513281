import React from "react";

function Email(props) {
    const handleChange = (event) => {
        props.handleChange(event.target.name, event.target.value);
      };
  return (
    <>
      <div className="p-2 flex flex-col">
        <label htmlFor="email" className="font-semibold">
          Email*
        </label>
        <input
          type="text"
          id="email"
          name="email"
          value={props.nominee.email}
          // placeholder="Enter Email"
          onChange={handleChange}
          // className="mt-2 p-2 bg-red border border-white rounded-xl text-white"
          className="mt-2 p-2 bg-white rounded-xl text-black"
        />
      </div>
    </>
  );
}

export default Email;
