import React from "react";

function Mobile(props) {
  const handleChange = (event) => {
    props.handleChange(event.target.name, event.target.value);
  };
  return (
    <>
      <div className="p-2 flex flex-col">
        <label htmlFor="mobile" className="font-semibold">
          Mobile*
        </label>
        <input
          type="number"
          id="mobile"
          // placeholder="Enter No."
          value={props.nominee.contactNumber}
          onChange={handleChange}
          name="contactNumber"
          // className="mt-2 p-2 bg-red border border-white rounded-xl text-white"
          className="mt-2 p-2 bg-white rounded-xl text-black"
        />
      </div>
    </>
  );
}

export default Mobile;
