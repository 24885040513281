import React from "react";

function PoiReferenceNo(props) {
    const handleChange = (event) => {
        props.handleChange(event.target.name,event.target.value)
    }
  return (
    <>
      <div className="p-2 flex flex-col">
        <label htmlFor="poiNumber" className="font-semibold">
          POI Reference Number*
        </label>
        <input
          type="text"
          id="poiNumber"
          name="poiReferenceNumber"
          value={props.nominee.poiReferenceNumber}
          // placeholder="Enter No."
          onChange={handleChange}
          // className="mt-2 p-2 bg-red border border-white rounded-xl text-white"
          className="mt-2 p-2 bg-white rounded-xl text-black"
        />
      </div>
    </>
  );
}

export default PoiReferenceNo;
