import React from "react";

export default function District(props) {
  const handleAddressChange = (event) => {
    props.handleAddressChange(
      "addressDto." + event.target.name,
      event.target.value
    );
  };
  return (
    <>
      <div className="p-2 flex flex-col">
        <label htmlFor="district" className="font-semibold">
          District*
        </label>
        <input
          type="text"
          id="district"
          name="district"
          value={props.nominee.addressDto.district}
          // placeholder="Enter District"
          onChange={handleAddressChange}
          // className="mt-2 p-2 bg-red border border-white rounded-xl text-white"
          className="mt-2 p-2 bg-white rounded-xl text-black"
        />
      </div>
    </>
  );
}
