import React from "react";

function IdentityProof(props) {
  const handleChange = async (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

    // Check if a file is selected
    const isFileSelected = !!file;

    props.handleChange(event.target.name, isFileSelected);

    if (isFileSelected) {
      if (allowedTypes.includes(file.type)) {
        const reader = new FileReader();

        reader.onloadend = async () => {
          // Convert the file data to base64
          const base64Data = reader.result.split(",")[1];
          // Pass the base64-encoded data to the parent component
          props.handleChange(event.target.name, base64Data);
        };

        // Read the file as a data URL
        reader.readAsDataURL(file);
      } else {
        alert("Please select a valid image file (jpg, png, jpeg).");
        event.target.value = null; // Clear the file input
        props.handleChange(event.target.name, null);
      }
    }
  };

  return (
    <>
      <div className="p-2 flex flex-col overflow-hidden">
        <label htmlFor="proofOfAddress" className="font-semibold">
          Identity Proof (only png, jpg) (file should be less than 4MB)
        </label>
        <input
          type="file"
          accept=".png,.jpg"
          id="proofOfAddress"
          name="proofOfAddress"
          onChange={handleChange}
          className="mt-2 p-2 bg-white rounded-xl text-black"
        />
      </div>
    </>
  );
}

export default IdentityProof;
