import React, { useState } from "react";
import Lion from "../../assets/verification/lion.svg";
import Flag from "../../assets/verification/flagText.svg";
import Bharat from "../../assets/verification/bharatText.svg";
import "./styles.css";
import RButton from "../../component/rButton";
import Loader from "../../component/loader";
import Verified from "../../assets/verification/verified.png"
import RImg from "../../component/rImg"

function Verification(props) {
  const { aadharResponseData, panCardDataDto } = props.data;

  
  const storedResponse = JSON.parse(sessionStorage.getItem("apiResponse"));
  if (storedResponse) {
    const data = JSON.parse(sessionStorage.getItem("apiResponse2"));
    const { dateOfBirth, name, panNumber,gender } = storedResponse;
  }
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    dob: storedResponse.dateOfBirth,
    name: storedResponse.name,
    panCardImage: "",
    panNumber:storedResponse.panNumber,
    gender:storedResponse.gender
  })
  // const aadharResponseData = 12
  // const panCardDataDto = null

  const handlePan = async () => {
    // Retrieve the xUserId from session storage
    const xUserId = sessionStorage.getItem("token").replace(/"/g, "");
    // const user = xUserId.slice("")
    
    try {
      const response = await fetch("https://kyc.finovo.tech:5555/api/v1/rekyc/digilocker/upload/pancard", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "xuserid": xUserId, // Include xuserid in the headers
        },
        body: JSON.stringify(formData),
      });
  
      // Handle the response as needed
      if (response.ok) {
        const data = await response.json();
        console.log("Success:", data);
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    const { name, files } = e.target;
  
    if (files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
  
      reader.onloadend = () => {
        let base64String = reader.result;
  
        // If the file is an image, remove the data:image/...;base64, prefix
        if (file.type.startsWith("image/")) {
          base64String = base64String.replace(/^data:image\/[a-zA-Z]+;base64,/, "");
        }
  
        setFormData((prev) => ({
          ...prev,
          [name]: base64String
        }));
      };
  
      reader.readAsDataURL(file);
    }
  };
  
  const handleClick = () => {
    sessionStorage.clear();
    window.location.reload();
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 5000)

  }


  if (!aadharResponseData) {
    return (<>
      <Loader open={loading} />
      <div className="email-container flex flex-col gap-20 items-center flex-wrap rounded-2xl p-3 bg-grey h-[90vh]">
        <div className="text-white text-[100px]">Oop's !</div>
        <div className="grey italic font-bold text-center rounded text-xl border px-2 w-[400px] p-4 bg-orange border border-white hover:border-2 cursor-text">
          <p>
            You have uploaded aadhar details previoiusly
            Kindly try another <br /> aadhar !
          </p>
          <button type="submit" className="bg-darkBlue mt-10 text-white font-thin p-2 rounded-lg border hover:border-2 border-white cursor-pointer"
            onClick={handleClick}
          >submit</button>
        </div>
      </div>
    </>)
  }


  return (
    <>
      <div className="verification-page flex flex-row rounded-t-2xl">
        <div className="left-head w-[50%] flex  items-center">
          <div className="verified-left flex flex-col justify-center px-12
          py-16">
            <p className="left-up text-white text-3xl  flex items-center gap-2">
              Verified <RImg
                src={Verified}
                alt="ryzKyc"
                width="35px"
                externalClassName="flex justify-center items-center"
                innerImgClassName="verified-tick"
              />
            </p>
            <p className="left-mid bg-silver text-xl text-white opacity-60">
              Aadhar & PAN
            </p>
            <div className="web-btn pt-6 max-[550px]:hidden" >
              <RButton
                buttonName="Proceed"
                handleButtonClick={() => props.handleKycStatus("BROKERAGE_PLAN")}
                bgColor=""
                color
                border
                text
                externalClassName="verified-btn"
              />
            </div>
          </div>
        </div>
        <div className="right-head w-[50%] flex flex-col justify-center items-center">
          <div className="right-box border border-white p-4 rounded-xl w-[100%]  my-10 mr-2 ">
            <div className="aadharPan-wrapper flex flex-col w-full">
              <div className="aadhar-card w-[100%] border rounded-lg bg-white mb-4">
                <div className="flex justify-around mb-2 ">
                  <img src={Lion} alt="" width="8%" />
                  <img src={Flag} alt="" width="70%" />
                </div>
                <div className="flex">
                  <div className="aadhar-image m-2">
                    <img
                      src={`data:img/jpeg;base64, ${aadharResponseData?.aadharProfilePhotoBase64 ?? ""
                        }`}
                      alt="Aadhar card of user"
                      className="border"
                      width="80px"
                    />
                  </div>
                  <div className="aadhar-info font-bold">
                    <p>Name: {aadharResponseData?.name ?? ""}</p>
                    <p>DOB: {panCardDataDto?.dob ?? ""}</p>
                    <p>Gender: {panCardDataDto?.gender ?? ""} </p>
                  </div>
                </div>
                <div className="aadhar-no text-center my-3 font-bold text-xl">

                  {aadharResponseData?.maskAadharNumber ?? ""}
                  <hr className="text-red" />
                </div>
                <div className="text-center font-bold">
                  आधार - आम आदमी का अधिकार
                </div>
              </div>
              {panCardDataDto ? (<>
                <div className="pan-card w-[100%] border rounded-lg">
                  <div className="flex justify-around">
                    <div className="">
                      <p className=" pan-heading1 text-sm text-center">आयकर विभाग</p>
                      <p className="pan-heading ">INCOME TAX DEPARTMENT</p>
                    </div>
                    <img src={Lion} alt="lion" width="8%" />
                    <img src={Bharat} alt="" width="40%" />
                  </div>
                  <div className="flex">
                    <img
                      src={`data:img/jpeg;base64, ${aadharResponseData?.aadharProfilePhotoBase64 ?? ""
                        }`}
                      alt="Pan card of user"
                      width="100px"
                      height="100px"
                      className="pan-image border ml-2"
                    />
                    <div className="flex justify-center w-[60%]">
                      <p className="pan-no">PAN CARD NUMBER</p>
                      <span className="font-bold "> {panCardDataDto?.panNumber ?? ""}</span>
                    </div>

                  </div>
                  <div className="aadhar-info ml-2">
                    <p>NAME: <span className="font-bold"> {panCardDataDto?.name ?? ""}</span></p>
                    <p>FATHER NAME: <span className="font-bold"> {aadharResponseData?.careOf ?? ""}</span></p>
                    <p>Date Of Birth:<span className="font-bold"> {panCardDataDto?.dob ?? ""}</span></p>
                  </div>
                </div>
              </>) : (
                <>
                  <div className="w-[100%] bg-white border rounded-lg">
                    <p className="font-bold text-center "><u>Your PanCard is not available</u> </p>
                    <div className="text-center flex justify-center flex-col text-lg">
                        <span>PLease upload your PanCard</span>
                      <p className="m-2 border border-primary p-2">
                      <input type="file" name="panCardImage" className="" onChange={handleChange} />
                      </p>
                        <button className="border rounded bg-green font-bold m-2"
                        onClick={handlePan}
                        >Submit</button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="mob-btn w-[100%] ml-12  mb-6 min-[550px]:hidden">
            <RButton
              buttonName="Proceed"
              handleButtonClick={() => props.handleKycStatus("BROKERAGE_PLAN")}
              bgColor=""
              color
              border
              text
              externalClassName="verified-btn"
            />
          </div>
        </div>
      </div>

    </>
  );
}

export default Verification 






// import React, { useState } from "react";
// import Lion from "../../assets/verification/lion.svg";
// import Flag from "../../assets/verification/flagText.svg";
// import Bharat from "../../assets/verification/bharatText.svg";
// import "./styles.css";
// import RButton from "../../component/rButton";
// import Loader from "../../component/loader";
// import Verified from "../../assets/verification/verified.png"
// import RImg from "../../component/rImg"

// function Verification(props) {
//   const { aadharResponseData, panCardDataDto } = props.data;

  
//   const storedResponse = JSON.parse(sessionStorage.getItem("apiResponse"));
//   if (storedResponse) {
//     const data = JSON.parse(sessionStorage.getItem("apiResponse2"));
//     const { dateOfBirth, name, panNumber } = storedResponse;
//   }
//   const [loading, setLoading] = useState(false)
//   const [formData, setFormData] = useState({
//     dob: storedResponse.dateOfBirth,
//     name: storedResponse.name,
//     panCardImage: "",
//     panNumber:storedResponse.panNumber
//   })
//   // const aadharResponseData = 12
//   // const panCardDataDto = null

//   const handlePan = async () => {
//     // Retrieve the xUserId from session storage
//     const xUserId = sessionStorage.getItem("token").replace(/"/g, "");
//     // const user = xUserId.slice("")
    
//     try {
//       const response = await fetch("https://kyc.finovo.tech:5555/api/v1/rekyc/digilocker/upload/pancard", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "xuserid": xUserId, // Include xuserid in the headers
//         },
//         body: JSON.stringify(formData),
//       });
  
//       // Handle the response as needed
//       if (response.ok) {
//         const data = await response.json();
//         console.log("Success:", data);
//       } else {
//         console.error("Error:", response.statusText);
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, files } = e.target;

//     if (files && files[0]) {
//       const file = files[0];
//       const reader = new FileReader();

//       reader.onloadend = () => {
//         let base64String = reader.result;

//         // If the file is a PDF, remove the data:application/pdf;base64, prefix
//         if (file.type === "application/pdf") {
//           base64String = base64String.replace("data:application/pdf;base64,", "");
//         }

//         setFormData((prev) => ({
//           ...prev,
//           [name]: base64String
//         }));
//       };

//       reader.readAsDataURL(file);
//     }
//   };

//   const handleClick = () => {
//     sessionStorage.clear();
//     window.location.reload();
//     setLoading(true)
//     setTimeout(() => {
//       setLoading(false)
//     }, 5000)

//   }


//   if (!aadharResponseData) {
//     return (<>
//       <Loader open={loading} />
//       <div className="email-container flex flex-col gap-20 items-center flex-wrap rounded-2xl p-3 bg-grey h-[90vh]">
//         <div className="text-white text-[100px]">Oop's !</div>
//         <div className="grey italic font-bold text-center rounded text-xl border px-2 w-[400px] p-4 bg-orange border border-white hover:border-2 cursor-text">
//           <p>
//             You have uploaded aadhar details previoiusly
//             Kindly try another <br /> aadhar !
//           </p>
//           <button type="submit" className="bg-darkBlue mt-10 text-white font-thin p-2 rounded-lg border hover:border-2 border-white cursor-pointer"
//             onClick={handleClick}
//           >submit</button>
//         </div>
//       </div>
//     </>)
//   }


//   return (
//     <>
//       <div className="verification-page flex flex-row rounded-t-2xl">
//         <div className="left-head w-[50%] flex  items-center">
//           <div className="verified-left flex flex-col justify-center px-12
//           py-16">
//             <p className="left-up text-white text-3xl  flex items-center gap-2">
//               Verified <RImg
//                 src={Verified}
//                 alt="ryzKyc"
//                 width="35px"
//                 externalClassName="flex justify-center items-center"
//                 innerImgClassName="verified-tick"
//               />
//             </p>
//             <p className="left-mid bg-silver text-xl text-white opacity-60">
//               Aadhar & PAN
//             </p>
//             <div className="web-btn pt-6 max-[550px]:hidden" >
//               <RButton
//                 buttonName="Proceed"
//                 handleButtonClick={() => props.handleKycStatus("CLIENT_DATA")}
//                 bgColor=""
//                 color
//                 border
//                 text
//                 externalClassName="verified-btn"
//               />
//             </div>
//           </div>
//         </div>
//         <div className="right-head w-[50%] flex flex-col justify-center items-center">
//           <div className="right-box border border-white p-4 rounded-xl w-[100%]  my-10 mr-2 ">
//             <div className="aadharPan-wrapper flex flex-col w-full">
//               <div className="aadhar-card w-[100%] border rounded-lg bg-white mb-4">
//                 <div className="flex justify-around mb-2 ">
//                   <img src={Lion} alt="" width="8%" />
//                   <img src={Flag} alt="" width="70%" />
//                 </div>
//                 <div className="flex">
//                   <div className="aadhar-image m-2">
//                     <img
//                       src={`data:img/jpeg;base64, ${aadharResponseData?.aadharProfilePhotoBase64 ?? ""
//                         }`}
//                       alt="Aadhar card of user"
//                       className="border"
//                       width="80px"
//                     />
//                   </div>
//                   <div className="aadhar-info font-bold">
//                     <p>Name: {aadharResponseData?.name ?? ""}</p>
//                     <p>DOB: {panCardDataDto?.dob ?? ""}</p>
//                     <p>Gender: {panCardDataDto?.gender ?? ""} </p>
//                   </div>
//                 </div>
//                 <div className="aadhar-no text-center my-3 font-bold text-xl">

//                   {aadharResponseData?.maskAadharNumber ?? ""}
//                   <hr className="text-red" />
//                 </div>
//                 <div className="text-center font-bold">
//                   आधार - आम आदमी का अधिकार
//                 </div>
//               </div>
//               {panCardDataDto ? (<>
//                 <div className="pan-card w-[100%] border rounded-lg">
//                   <div className="flex justify-around">
//                     <div className="">
//                       <p className=" pan-heading1 text-sm text-center">आयकर विभाग</p>
//                       <p className="pan-heading ">INCOME TAX DEPARTMENT</p>
//                     </div>
//                     <img src={Lion} alt="lion" width="8%" />
//                     <img src={Bharat} alt="" width="40%" />
//                   </div>
//                   <div className="flex">
//                     <img
//                       src={`data:img/jpeg;base64, ${aadharResponseData?.aadharProfilePhotoBase64 ?? ""
//                         }`}
//                       alt="Pan card of user"
//                       width="100px"
//                       height="100px"
//                       className="pan-image border ml-2"
//                     />
//                     <div className="flex justify-center w-[60%]">
//                       <p className="pan-no">PAN CARD NUMBER</p>
//                       <span className="font-bold "> {panCardDataDto?.panNumber ?? ""}</span>
//                     </div>

//                   </div>
//                   <div className="aadhar-info ml-2">
//                     <p>NAME: <span className="font-bold"> {panCardDataDto?.name ?? ""}</span></p>
//                     <p>FATHER NAME: <span className="font-bold"> {aadharResponseData?.careOf ?? ""}</span></p>
//                     <p>Date Of Birth:<span className="font-bold"> {panCardDataDto?.dob ?? ""}</span></p>
//                   </div>
//                 </div>
//               </>) : (
//                 <>
//                   <div className="w-[100%] bg-white border rounded-lg">
//                     <p className="font-bold text-center "><u>Your PanCard is not available</u> </p>
//                     <div className="text-center flex justify-center flex-col text-lg">
//                         <span>PLease upload your PanCard</span>
//                       <p className="m-2 border border-primary p-2">
//                       <input type="file" name="panCardImage" className="" onChange={handleChange} />
//                       </p>
//                         <button className="border rounded bg-green font-bold m-2"
//                         onClick={handlePan}
//                         >Submit</button>
//                     </div>
//                   </div>
//                 </>
//               )}
//             </div>
//           </div>
//           <div className="mob-btn w-[100%] ml-12  mb-6 min-[550px]:hidden">
//             <RButton
//               buttonName="Proceed"
//               handleButtonClick={() => props.handleKycStatus("CLIENT_DATA")}
//               bgColor=""
//               color
//               border
//               text
//               externalClassName="verified-btn"
//             />
//           </div>
//         </div>
//       </div>

//     </>
//   );
// }

// export default Verification 
