import React, { useState } from "react";
import "./styles.css";

function Dob(props) {
  const { dateOfBirth } = props.nominee;
  const dob = new Date(dateOfBirth);
  const currentDate = new Date();
  const age = currentDate.getFullYear() - dob.getFullYear();

  const [popUp, setPopUp] = useState(age < 18);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "dateOfBirth") {
      const newDob = new Date(value);
      const newAge = currentDate.getFullYear() - newDob.getFullYear();
      setPopUp(newAge < 18);
    }

    props.handleChange(name, value);
  };

  const handleSubmit = () => {
    setPopUp(false);
  };
  return (
    <>
      {popUp && (
        <div className="main-popUp">
          <div className="popUp-container sm:bg-red md:bg-navy md:h-[55vh] flex flex-col items-center">
            <div className="w-full flex justify-end md:mr-16 m-2 p-2">
              <p className="py-2 px-3 md:bg-red sm:bg-navy rounded-full cursor-pointer"
                onClick={() => { setPopUp(false) }}
              >
                X
              </p>
            </div>
            <div className=" flex flex-wrap justify-center items-center">
              <div className="p-2 flex flex-col">
                <label htmlFor="guardianName" className="font-semibold">
                  Guardian Name
                </label>
                <div><input
                  type="text"
                  id="guardianName"
                  name="guardianName"
                  value={props.nominee.guardianName}
                  onChange={handleChange}
                  className="mt-2 p-2 bg-white rounded-xl text-black"
                /></div>
              </div>




              <div className="p-2 flex flex-col">
                <label htmlFor="guardianRelation" className="font-semibold">
                  Guardian Relation
                </label>
                <div><input
                  type="text"
                  id="guardianRelation"
                  name="guardianRelation"
                  value={props.nominee.guardianRelation}
                  onChange={handleChange}
                  className="mt-2 p-2 bg-white rounded-xl text-black"
                /></div>
              </div>

              <div className="p-2 flex flex-col">
                <label htmlFor="guardianAddress" className="font-semibold">
                  Guardian Address
                </label>
                <div><input
                  type="text"
                  id="guardianAddress"
                  name="guardianAddress"
                  value={props.nominee.guardianAddress}
                  onChange={handleChange}
                  className="mt-2 p-2 bg-white rounded-xl text-black"
                /></div>
              </div>
              <div className=" p-2 flex flex-col">
                <label htmlFor="guardianIdProof" className="font-semibold">
                  Guardian ID Proof
                </label>
                <div><input
                  type="text"
                  id="guardianIdProof"
                  name="guardianIdProof"
                  value={props.nominee.guardianIdProof}
                  onChange={handleChange}
                  className="mt-2 p-2 bg-white rounded-xl text-black"
                /></div>
              </div>
              <div className="p-2 flex flex-col">
                <label htmlFor="guardianIdProofNumber" className="font-semibold">
                  Guardian ID Proof Number
                </label>
                <div><input
                  type="text"
                  id="guardianIdProofNumber"
                  name="guardianIdProofNumber"
                  value={props.nominee.guardianIdProofNumber}
                  onChange={handleChange}
                  className="mt-2 p-2 bg-white rounded-xl text-black"
                /></div>
              </div>
            </div>
            <button onClick={handleSubmit} className="bg-blue py-2 px-3 m-4">Submit</button>
          </div>
        </div>
      )}

      <div className="p-2 flex flex-col">
        <label htmlFor="dob" className="font-semibold">
          DOB*
        </label>
        <input
          type="date"
          id="dob"
          max="9999-12-31"
          name="dateOfBirth"
          value={props.nominee.dateOfBirth}
          onChange={handleChange}
          className="mt-2 p-2 bg-white rounded-xl text-black"
        />
      </div>

    </>
  );
}

export default Dob;
