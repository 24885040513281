import React from "react";
//new kyc nirman_1.1v
export default function Adddress1(props) {
  const handleAddressChange = (event) => {
    props.handleAddressChange(
      "addressDto." + event.target.name,
      event.target.value
    );
  };
  return (
    <>
      <div className="p-2 flex flex-col">
        <label htmlFor="address1" className="font-semibold">
          HouseNumber/Street*
        </label>
        <input
          type="text"
          id="address1"
          name="street"
          // placeholder="Enter Address"
          value={props.nominee.addressDto.street}
          onChange={handleAddressChange}
          // className="mt-2 p-2 bg-red border border-white rounded-xl text-white"
          className="mt-2 p-2 bg-white rounded-xl text-black"
        />
      </div>
    </>
  );
}
