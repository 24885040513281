import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const BrokeragePlan = (props) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    props.handleKycStatus("CLIENT_DATA");
    setShow(false);
  }

  const handlePlan = (plan) => {
    console.log(`Handling plan: ${plan}`);
    const storedResponse = JSON.parse(sessionStorage.getItem("apiResponse"));
    if (storedResponse) {
      const { name, phoneNumber, email } = storedResponse;

      if (name && phoneNumber) {
        const url = `https://kyc.finovo.tech:5555/api/v1/payment/brokerage/plan?name=${name}&email=${email}&phoneNumber=${phoneNumber}&plan=${plan}`;

        fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => response.json())
          .then(data => {
            console.log('Success:', data);
            if (data.message) {
              sessionStorage.setItem("rekycStatus", "\"CLIENT_DATA\"");
              window.location.href = data.message;
            } else {
              console.error('Redirect URL not found in the response');
            }
          })
          .catch(error => {
            console.error('Error:', error);
          });
      } else {
        console.error('Name or phone number is not available in session storage');
      }
    } else {
      toast.error("Payment Gateway is not working");
    }
  }
  return (
    <>
      {
        show && (
          
          <div
          className="relative h-screen flex flex-col border-2 border-white w-full sm:overflow-scroll md:overflow-hidden"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            //   color: "black",
            backgroundColor: "grey",
            padding: "0.5rem",
            borderRadius: "0.5rem",
            boxShadow:
              "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
          }}
        >
          <div className='w-full text-end'>
            <button className='border py-2 px-3 rounded bg-red text-white border-2 border-black'
         onClick={handleClose}
            >
              Close
            </button>
          </div>
          <p className='bg-blue text-white text-center rounded-lg rounded-b-none sm:text-lg md:text-2xl xl:text-3xl p-3 m-4'>
            Brokerage Plan
          </p>
          <div className='flex sm:flex-col md:flex-row sm:gap-4 md:gap-10 xl:gap-14 sm:overflow-y-scroll md:overflow-hidden'>
            <div className='bg-grey rounded-2xl text-white px-3 py-2 md:w-[30%]'>
              <div className='flex items-center justify-between px-3 text-lg w-full'>
                <p className='w-full sm:visible md:hidden'>PLAN</p>
                <p className='w-full sm:hidden md:block'>GOLD</p>
                <p className='sm:visible md:invisible w-1/2 border m-2 ml-24 rounded px-3 py-2 font-bold bg-white text-black'
                  onClick={() => handlePlan('GOLD')}
                >GOLD</p>
                {/* <p className='sm:invisible md:visible'>A</p> */}
              </div>
              <hr className='mb-4' />
              <h3 className='xltext-lg underline'><span className='text-orange text-xl'>Rs 1500/- </span><br /> per month, Having 125 FREE ORDER</h3>
              <br />
              <p className='sm:text-sm md:text-md'>- This plan includes worthiness of 4250 Rupees
                It includes:</p>
              <ul className='list-disc p-4 sm:text-sm md:text-md'>
                <li>Free A/C opening for which charge Rs 500</li>
                <li>Free API for Trading and Market Data= Rs 2000</li>
                <li>125 free order x Rs 14 = Rs 1750</li>
                <li>Total package worth = Rs 4250</li>
              </ul>
              <br />
              <span className='text-sm'>(and additionally we will provide you calls to trade)</span>
              <p className='p-2'>Total Saving Of <span className='text-lightBlue'>Rs 3595/-</span>  </p>
              <hr className='xl:p-2' />
              <div className='w-full flex justify-center'>
                <button className='bg-white text-black text-xl rounded px-3 py-2 font-bold sm:invisible md:visible'
                  onClick={() => handlePlan('GOLD')}
                >GOLD</button>
              </div>
            </div>
    
            <div className='bg-grey rounded-2xl text-white px-3 py-2 md:w-[30%]'>
              <div className='flex items-center justify-between px-3 text-lg '>
              <p className='w-full sm:visible md:hidden'>PLAN</p>
              <p className='w-full sm:hidden md:block'>TITANIUM</p>
                <p className='sm:visible md:invisible border m-2 ml-24 rounded px-3 py-2 font-bold bg-white text-black'
                  onClick={() => handlePlan('TITANIUM')}
                >TITANIUM</p>
                {/* <p className='sm:invisible md:visible'>B</p> */}
              </div>
              <hr className='mb-4' />
              <h3 className='xl:text-lg underline'><span className='text-orange text-xl'>Rs 2500/-</span><br /> per month, Having 175 FREE ORDER</h3>
              <br />
              <p className='sm:text-sm md:text-md'>- This plan includes worthiness of 6000 Rupees
                It includes:
              </p>
              <ul className='list-disc p-4 sm:text-sm md:text-md'>
                <li>Free A/C opening for which charge Rs 500</li>
                <li>Free API for Trading and Market Data= Rs 2000</li>
                <li>175 free order x Rs 14 = Rs 2450<br />
                  And 15 offline Order X Rs 70 = Rs 1050</li>
                <li>Total package worth = Rs 6000</li>
              </ul>
              <span className='text-sm'>(and additionally we will provide you calls to trade)</span>
              <p className='p-2'>Total Saving Of <span className='text-lightBlue'>Rs 3500/- </span>  </p>
              <hr className='xl:p-2' />
              <div className='w-full flex justify-center'>
                <button className='bg-white text-black text-xl rounded px-3 py-2 font-bold sm:invisible md:visible'
                  onClick={() => handlePlan('TITANIUM')}
                >TITANIUM</button>
              </div>
            </div>
            <div className='bg-grey rounded-2xl text-white px-3 py-2 md:w-[30%]'>
              <div className='flex justify-between items-center px-3 text-lg '>
              <p className='w-full sm:visible md:hidden'>PLAN</p>
              <p className='w-full sm:hidden md:block'>PLATINUM</p>
                <p className='sm:visible md:invisible border m-2 ml-24 rounded px-3 py-2 font-bold bg-white text-black'
                  onClick={() => handlePlan('PLATINUM')}
                >PLATINUM</p>
                {/* <p className='sm:invisible md:visible'>C</p> */}
              </div>
              <hr className='mb-4' />
              <h3 className='xl:text-lg underline'><span className='text-orange text-xl'>Rs 5555/-</span><br /> Only for a Quarter( 3 months) Having 225 FREE ORDER</h3>
              <p className='sm:text-sm md:text-md'>- This plan includes worthiness of 9150 Rupees
                It includes:</p>
              <ul className='list-disc p-4 sm:text-sm md:text-md'>
                <li>Free A/C opening for which charge Rs 500</li>
                <li> Free API for Trading and Market Data= Rs 2000</li>
                <li>225 free order x Rs 14 = Rs 3150<br />
                  And 50 offline Order X Rs 70 = Rs 3500</li>
                <li> Total package worth = Rs 9150</li>
              </ul>
              <span className='text-sm'>(and additionally we will provide you calls to trade)</span>
              <p className='p-2'>Total Saving Of <span className='text-lightBlue'>Rs 3595/-</span>  </p>
              <hr className='xl:p-2' />
              <div className='w-full flex justify-center'>
                <button
                  className='bg-white text-black text-xl rounded px-3 ml py-2 font-bold sm:invisible md:visible'
                  onClick={() => handlePlan('PLATINUM')}
                >PLATINUM</button>
              </div>
            </div>
          </div>
        </div>


        )
      }
      {/* </div>    */}
    </>
  )
}

export default BrokeragePlan


