import React, { useEffect } from 'react';

function LockedAccount(props) {
  const handleGoBack = () => {
    // Add your logic here to go back or perform any other action
    window.location.reload();
    props.handleKycStatus("CLIENT_ID")
  };
  useEffect(() => {
    sessionStorage.clear();
  })

  return (
    <>
    <div className='modal-wrapper'></div>
    <div className='modal-container h-[50vh] flex justify-center items-center'>
      <div className='w-[70%] bg-blue py-10'>
        <h1 className='p-4 text-2xl text-white font-bold'>
        Your Account verifciation under process!
        </h1>
        <button
          className='bg-red px-3 py-2 font-bold'
          onClick={handleGoBack}
        >
          Go Back
        </button>
      </div>
    </div>
    </>
  );
}

export default LockedAccount;
