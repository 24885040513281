import React from "react";

export default function Name(props) {
  const handleChange = (event) => {
    props.handleChange(event.target.name, event.target.value);
  };
  return (
    <>
      <div className="p-2 flex flex-col">
        <label htmlFor="name" className="font-semibold">
          Name*
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={props.nominee.name}
          // placeholder="Enter Name"
          onChange={handleChange}
          // className="mt-2 p-2 bg-red border border-white rounded-xl text-white"
          className="mt-2 p-2 bg-white rounded-xl text-black"
        />
      </div>
    </>
  );
}
