import React, { useState } from "react";
import useApi from "../../src/hooks/useApi";
import { toast, ToastContainer } from "react-toastify";
import RButton from "../component/rButton";
import RImg from "../component/rImg";
import Logo from "../assets/kyc/nirman_Logo.png";
import ClosureInfo from "../clousure/closureInfo"
// import { useNavigateF } from "react-router-dom";

import "./style.css";
import Loader from "../component/loader";

const Clousure = (props) => {
  const [clientId, setClientId] = useState("");
  const [dropdown, setDropdown] = useState("No finance to invest");
  const [showInput, setShowInput] = useState(false);
  const [valid, setValid] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [otp, setOtp] = useState("");
  const [swap, setSwap] = useState(false);
  const [loading, setLoading] = useState(false);

  // const navigate = useNavigate();

  const handleChange = (event) => {
    setClientId(event.target.value);
  };

  const handleSubmit = async (event, value) => {
    sessionStorage.setItem("reason", JSON.stringify(dropdown));
    event?.preventDefault();
    const id = value ?? clientId;
    const regex = /^([a-zA-Z0-9_-]){3,9}$/;
    const isValid = regex.test(id);
    try{
      setLoading(true);
      const response = await props.handlePostRequest(
        { value: clientId },
        `rekyc/send/otp`
      );
      setLoading(false);
      setValid(true);

    } catch {
      setLoading(false);
       setErrorMsg("");
    }
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleCancelOTP = () => {
    setValid(false);
    setOtp("");
    setOtpErrorMsg("");
  };

  const handleOtpSubmit = (event) => {
    const body = {
      dpId: clientId,
      otp: otp,
    };
    event.preventDefault();
    setLoading(true);
    fetch(`https://kyc.finovo.tech:5555/api/v1/closure/user/verify/otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        setLoading(false);
        setSwap(true)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        } else if (response.ok) {
          // navigate("/closure-info");
        }
        return response.json();
      })
      .then((data) => {
        sessionStorage.setItem("apiResponse", JSON.stringify(data));
      })
      .catch((error) => {
        setSwap(false)
        console.error("Error:", error);
        if (error.message.includes("400")) {
          setSwap(false)
          toast.error("Your Closure Account is already exist");
        } else if (error.message.includes("401")) {
          toast.error("Invalid OTP");
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  };

  const handleShowDPID = (e) => {
    const selectedOption = e.target.value;
    if (selectedOption === "No finance to invest") {
      setShowInput(false);
      setDropdown(e.target.value);
    } else if (selectedOption === "Not interested in Treading") {
      setShowInput(false);
      setDropdown(e.target.value);
    } else if (selectedOption === "other") {
      setDropdown("");
      setShowInput(true);
    }
  };

  return (
    <>
      <div>
        {loading && <Loader open={loading} />}
        {!swap&&(<div className="flex justify-center items-center flex-col">
          <div className="sm:border  md:border-2 lg:border rounded-xl shadow-2xl sm:w-[90%] md:w-[50%] mx-auto">
            <div className="px-3">
              <p className="font-bold md:text-3xl text-center sm:text-base">
                Closure
              </p>
              <br />
              <br />
              <div>
                <p className="font-bold sm:text-base md:text-lg">
                  Reason for Closing the Account
                </p>
                <br />
                <select
                  className="w-full border p-3 rounded-t-xl"
                  onChange={handleShowDPID}
                >
                  <option value="No finance to invest">
                    No finance to invest
                  </option>
                  <option value="Not interested in Treading">
                    Not interested in Trading
                  </option>
                  <option value="other">Other</option>
                </select>
                {showInput && (
                  <input
                    autoFocus={true}
                    type="text"
                    name="dropdown"
                    value={dropdown}
                    onChange={(e) => setDropdown(e.target.value)}
                    placeholder="Enter reason"
                    className="numberInput p-3 mt-3 rounded-xl spin-button-none border text-black w-full"
                  />
                )}
                <br />
                <br />
                {!valid && (
                  <>
                    <div className="login-container">
                      <div className="mb-3">
                        <p className="right-content text-2xl text-black sm:text-base md:text-lg">
                          Enter Client ID
                        </p>
                      </div>
                      <div className="mb-2">
                        <input
                          autoFocus={true}
                          type="text"
                          name="clientId"
                          id="clientId"
                          value={clientId}
                          onChange={handleChange}
                          placeholder="Enter Client ID"
                          className="numberInput p-3 rounded-xl spin-button-none border text-black w-full"
                        />
                      </div>
                      <p className="text-red mb-5">{errorMsg}</p>
                      <RButton
                        handleButtonClick={handleSubmit}
                        buttonName="Check Eligibility"
                        bgColor="bg-navy text-white"
                        externalClassName="sm:text-xs md:text-lg"
                        type={"submit"}
                      />
                    </div>
                  </>
                )}
                {valid ? (
                  <>
                    <div className="">
                      <p className="text-black sm:text-sm md:text-2xl mb-1 font-semibold">
                        Enter the OTP sent on
                        <br />
                        to your mobile no. or email id
                      </p>
                      <div className="flex">
                        <p className="mb-3 opacity-70 text-black font-bold p-1 rounded border bg-green">
                          CLient Code: {clientId}
                        </p>
                      </div>
                      <div className="mb-3">
                        <input
                          autoFocus
                          type="number"
                          name="otp"
                          id="otp"
                          value={otp}
                          onChange={handleOtpChange}
                          placeholder="Enter otp"
                          className="text-black p-3 rounded-xl spin-button-none border w-full"
                        />
                      </div>
                      <div className="mob-button flex justify-between">
                        <p
                          onClick={handleCancelOTP}
                          className="cursor-pointer text-white border p-1 rounded bg-blue "
                        >
                          Cancel OTP
                        </p>
                        <p
                          onClick={handleSubmit}
                          className="cursor-pointer text-white border p-1 rounded bg-blue "
                        >
                          Resend
                        </p>
                      </div>
                      <RButton
                        handleButtonClick={handleOtpSubmit}
                        buttonName="Continue"
                        bgColor="bg-tertiary"
                        externalClassName="p-2 mt-2 text-white bg-navy"
                        type={"submit"}
                      />
                    </div>
                  </>
                ) : null}
                <br />
                <div className="md:p-4">
                  <p className="font-bold text-xl sm:text-base md:text-lg ">
                    NOTE :
                  </p>
                  <div className="m-3">
                    <ul className="list-disc sm:text-sm md:text-base">
                      <li>
                        Client will be able to close the demat account without
                        mandatorily giving any reasons.
                      </li>
                      <li>
                        This Eligibility will ensure whether account's holding
                        and ledger is free or not.
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                <p className="note-main text-xs mt-6 text-black font-thin absolute bottom-0 left-0 sm:hidden md:visible">
                  NOTE: NIRMAN SHARE BROKERS PVT LTD IS DOING CLIENT BASED <br />{" "}
                  TRADING AND PRO ACCOUNT TRADING.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>)}
        {swap && (
          <ClosureInfo/>
        )}
        <ToastContainer />
      </div>
    </>
  );
};

export default Clousure;
