import React, { useState, useEffect } from "react"
import useApi from "../hooks/useApi"
import useSessionStorage from "../hooks/useSession"
import { toast, ToastContainer } from "react-toastify"
import ClientData from "./clientData"
import ClientIdLogin from "./clientLoginId"
import DigiLocker from "./digiLocker"
import Verification from "./verification"
import NirmanLogo from "../../src/assets/RYZ.png"
import Rejected from "./Rejected"
import LockedAccount from "./LockedAccount"
import Loader from "../component/loader"
import ThankYou from "./thankyouPage"
import Finovo from "../assets/Finovo/finovoLogo.png"
import Closure from "../clousure"
import FetchData from "../reKyc/fetchDigi"
import BrokeragePlan from "./brokeragePlan"
function Rekyc() {
  sessionStorage.setItem("username", "");
  const [response, setResponse] = useState("")
  const [response2, setResponse2] = useState("")
  const { loading, postData, error, fetchData } = useApi()
  const [legalityData, setLegalityData] = useState()
  const [reKycStatus, setReKycStatus] = useSessionStorage("rekycStatus", "")
  const [kycStage, setKycStage] = useSessionStorage("kycStage", "")
  const handlePostRequest = async (data, url, isPatch = false) => {
    try {
      const response = await postData(url, data, isPatch)
      const resReKycStatus = await response.rekycStatus
        ; (await resReKycStatus) && setReKycStatus(resReKycStatus)
      setResponse(response)
      sessionStorage.setItem("apiResponse", JSON.stringify(response))
    } catch (e) {
      const err = await e
      toast.error(err.message ?? "Something went wrong")
    }
  }
  const handlePostRequest2 = async (data, url, isPatch = false) => {
    try {
      const response2 = await postData(url, data, isPatch)
      const resReKycStatus = await response2.rekycStatus
        ; (await resReKycStatus) && setReKycStatus(resReKycStatus)
        setResponse2(response2)
      sessionStorage.setItem("apiResponse2", JSON.stringify(response2))
    } catch (e) {
      const err = await e
      toast.error(err.message ?? "Something went wrong")
    }
  }

  const handleKycStatus = val => {
    setReKycStatus(val)
  }
  const isToken = () => {
    const token = sessionStorage.getItem("token")
    if (token?.length > 15) return true
    return false
  }
  const handleFetchData = async url => {
    try {
      const response = await fetchData(url)
      const resReKycStatus = await response?.rekycStatus
        ; (await resReKycStatus) && setReKycStatus(resReKycStatus)
      setResponse(response)
      const response2 = await fetchData(url)
      const resReKycStatus2 = await response2?.rekycStatus
        ; (await resReKycStatus2) && setReKycStatus(resReKycStatus2)
      setResponse(response2)
    } catch (e) {
      const err = await e
      toast.error("Something went wrong")
    }
  }

  useEffect(() => {
    const pathName = window?.location?.pathname;
    const urlParams = new URLSearchParams(window.location.search);
    const documnetIds = urlParams.get("documentId");
    if (pathName === "/legality/callback" && documnetIds && isToken()) {
      const url = `${origin}${pathName}`;
      const newURL = url.replace("/legality/callback", "");
      window.history.replaceState({}, document.title, newURL);
      handleFetchData(`esign/esign/${documnetIds}`);
      setLegalityData(documnetIds);
    }else if (pathName === "/closure") {
      setReKycStatus("CLOSURE");
      const url = `${origin}${pathName}`;
      const newURL = url.replace("/closure", "");
      window.history.replaceState({}, document.title, newURL);
      // window.location.reload();
    }
  }, []);

  const renderKycComponent = () => {
    const value = sessionStorage.getItem("username");
    if (value === "") {
      switch (reKycStatus) {
        case "CLIENT_ID":
          return (
            <ClientIdLogin
              handlePostRequest={handlePostRequest}
              response={response}
            />
          )
        case "CLIENT_DATA":
          return <ClientData handlePostRequest={handlePostRequest} />
        case "DIGILOCKER":
          return <DigiLocker handlePostRequest2={handlePostRequest2} />
        case "VERIFICATION":
          return (
            <Verification handleKycStatus={handleKycStatus} data={response2} />
          )
        case "REJECTED":
          return (
            <Rejected
              handleKycStatus={handleKycStatus}
              handlePostRequest={handlePostRequest}
            />
          )
        case "REQUESTED":
          return (
            <LockedAccount
              handleKycStatus={handleKycStatus}
              handlePostRequest={handlePostRequest}
              response={response}
            />
          )
        case "BROKERAGE_PLAN":
          return (
            <BrokeragePlan
              handleKycStatus={handleKycStatus}
              handlePostRequest={handlePostRequest}
              response={response}
            />
          )
        case "LOCKED":
          return (
            <LockedAccount
            handleKycStatus={handleKycStatus}
              handlePostRequest={handlePostRequest}
              response={response}
            />
          )
        case "THANKYOU":
          return (
            <ThankYou
              handleKycStatus={handleKycStatus}
              handlePostRequest={handlePostRequest}
              response={response}
            />
          )
        case "CLOSURE": // Added case for CLOSURE
          return (
            <Closure handlePostRequest={handlePostRequest} />
          )
        default:
          return (
            <ClientIdLogin
              handlePostRequest={handlePostRequest}
              response={response}
            />
          )

      }
    } else {
      return null; // If value is not empty, return null
    }
  }
  const Logout = () => {
    sessionStorage.clear();
    window.location.reload()
  }
  return (
    <main className="relative h-screen">
      <Loader open={loading} />
      <section className="flex justify-between items-center sm:pl-6 md:pl-12 sm:pr-8 md:pr-16 pt-6">
        <div className="">
          <img className="sm:w-[60px] md:w-[80px]" src={NirmanLogo} alt="" />
        </div>

        <div>
          <p className={`text-red font-bold sm:text-xl md:text-xl border p-3 rounded-xl cursor-pointer ${response.rekycStatus === "CLIENT_DATA" ? "block" : "hidden"}`} onClick={Logout}>LOGOUT</p>
        </div>
      </section>
      <div className="min-[780px]:mx-10 max-[780px]:mx-2">
        {response.lockStatus === "LOCKED" ? (
          <LockedAccount handleKycStatus={handleKycStatus} />
        ) : (
          <>
            {" "}
            {renderKycComponent()}
            <div className="flex justify-center items-center text-sm fixed bottom-0 right-0  ">
              <p className="font-bold sm:hidden md:block">All rights reserved ©2024 Powered by &nbsp;</p>{" "}
              <a href="https://www.finovo.tech/"><img src={Finovo} className="w-[70px]" /></a>
            </div>
            <p className={`note-main text-xs mt-6 text-black font-thin sm:hidden md:block absolute bottom-0 ${reKycStatus === "CLIENT_DATA" ? "hidden" : "block"}`}>
              NOTE: RYZ MARKET IS DOING CLIENT BASED <br />
              TRADING AND PRO ACCOUNT TRADING.
            </p>
          </>
        )}
      </div>
    </main>
  )
}

export default Rekyc