import React, { useState, useEffect } from "react";
import useApi from "../../hooks/useApi";
import { extractParamsValue } from "../../utils/function";
import "./styles.css";
import { toast, ToastContainer } from "react-toastify";
import useSessionStorage from "../../hooks/useSession";

function DigiLocker(props) {
    const [selectedTab, setSelectedTab] = useState(0);
    const [xUserId, setXUserId] = useSessionStorage("xuserid", "");
    const [codeChallenger, setCodeChallenger] = useState("");
    const { fetchData, loading } = useApi();
    const [digiInfo, setDigiInfo] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const extractCodeFromURL = () => {
        const { pathname, search, origin } = window.location;


        if (pathname.includes("/call") && search.includes("code=")) {
            const url = `${origin}${pathname}`;
            const newURL = url.replace("/call", "");
            // Update the URL in the browser without refreshing the page
            window.history.replaceState({}, document.title, newURL);
        }
    };
    const fetchCodeChallenger = async () => {
        try {
            const data = await fetchData("rekyc/digilocker/code/challenger");
            await setCodeChallenger(data?.codeChallenger);
        } catch (error) { }
    };

    const handleDigiLocker = () => {
        if (codeChallenger) {
            const stateId = "rmsign" + Math.random().toString(36).substring(7);
            sessionStorage.setItem("state", stateId);

            const redirectUri = encodeURIComponent(
                "https://www.rekyc.ryz.market/call"
            );
            const digiLockerUrl = `https://digilocker.meripehchaan.gov.in/public/oauth2/1/authorize?response_type=code&client_id=FX43913E9F&state=${stateId}&redirect_uri=${redirectUri}&code_challenge=${codeChallenger}&code_challenge_method=S256`;

            window.location.href = digiLockerUrl;
        } else {
            toast.info("We are fetching data from DIGILOCKER. Kindly Wait!!!");
        }
    };




    useEffect(() => {
        const codeValue = extractParamsValue(window.location.href, "code");
        if (codeValue) {
            console.log("=========>", codeValue);
            sessionStorage.setItem("codevalue", JSON.stringify(codeValue))
            extractCodeFromURL()
            props.handlePostRequest2(
                { code: codeValue },
                "rekyc/digilocker/access-details/user"
            )
        } else {
            fetchCodeChallenger();
        }
    }, []);

    return (
        <div>
            {showModal && (
                <>
                    <div className="modal-wrapper "></div>
                    <div className="modal-container bg-white p-6 rounded-xl">
                        <button
                            className="cursor-pointer font-bold text-black relative z-20"
                            onClick={handleDigiLocker}
                            disabled={!Boolean(codeChallenger)}
                        >
                            Proceed to Digilocker
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}

export default DigiLocker;