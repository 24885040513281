import React from 'react'

export default function Country(props) {
    const handleAddressChange = (event) => {
        props.handleAddressChange(
          "addressDto." + event.target.name,
          event.target.value
        );
      };
  return (
    <>
    <div className="p-2 flex flex-col">
        <label htmlFor="country" className="font-semibold">
          Country*
        </label>
        <input
          type="text"
          id="country"
          name="country"
          // placeholder="Enter Country"
          onChange={handleAddressChange}
          value={props.nominee.addressDto.country}
          // className="mt-2 p-2 bg-red border border-white rounded-xl text-white"
          className="mt-2 p-2 bg-white rounded-xl text-black"
        />
      </div>
    </>
  )
}

 