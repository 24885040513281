import "./App.css";
import React from "react";
import Rekyc from "./reKyc";
import Image from "./reKyc/Image.js"
import PhotoCapturePage from "./reKyc/uploadPhotoSugnature/index.jsx";

function App() {

  return (
    <Rekyc/>
    // <Image />
    // <PhotoCapturePage/>
  );
}

export default App;





