import React, { useState, useEffect } from "react";
import { UploadPhotoAndSignature } from "../uploadPhotoSugnature";
import RButton from "../../component/rButton";
import CameraCapture from "../../component/captureImage";
import Modal from "@mui/material/Modal";
import Loader from "../../component/loader";
import { toast, ToastContainer } from "react-toastify";
import useSessionStorage from "../../hooks/useSession";
  

const Rejected = (props) => {
  const [imageCapture, setImageCapture] = useState(false);
  const [loader,setLoader]=useState(false)
  const [xUserId, setXUserId] = useSessionStorage("token", "");
  const storedResponse = JSON.parse(sessionStorage.getItem("apiResponse"));
  const [submit,setSubmit]=useState(false)
  const Arr = storedResponse.listOfRejectedDocuments;

  const [formData, setFormData] = useState({
    PAN_CARD: "",
    IMAGE: "",
    SIGNATURE: "",
    FINANCIAL_PROOF: "",
    BANK_PROOF: "",
  });

  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];

    // Check if the file is an image
    if (file.type.includes("image")) {
      getBase64(file, e.target.name,true);
    } else {
      getBase64(file, e.target.name,false);
      // For other file types, directly update formData
      // setFormData((prev) => ({ ...prev, [e.target.name]: file }));
    }
  };

  const getBase64 = (file, name, isImage) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64Data = reader.result.split(',')[1];
      if (isImage) {
        base64Data = 'data:image/' + file.type.split('/')[1] + ';base64,' + base64Data;
      }
      setFormData((prev) => ({ ...prev, [name]: base64Data }));
    };
  };

  const cancelImage = (name) => {
    setFormData((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSendData = async () => {
    try {
      // Check if any required fields are empty
      const isValid = Arr.every((doc) => formData[doc] !== "");
    if (!isValid) {
      alert("Please fill in all the required fields before submitting.");
      return;
    }
    setLoader(true)
      if (xUserId) {
        setXUserId(xUserId); // Assuming setXUserId is a state updater function
      }
      
      const response = await fetch(
        "https://kyc.finovo.tech:5555/api/v1/rekyc/rejected/docs/submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "xuserid": xUserId,
          },
          body: JSON.stringify({ rejectedDocsList: formData }),
        }
      );
  
      if (response.ok) {
        setLoader(false)
        setSubmit(true)
        // props.handleKycStatus("CLIENT_ID"); // Assuming handleKycStatus is a prop function
        setTimeout(() => {
          sessionStorage.clear();
        window.location.reload();
        },5000)

      } else {
        toast.error("Failed to submit form data");
      }
    } catch (error) {
      toast.error(`Error occurred while submitting form data: ${error.message}`);
    }
  };

  const handleCaptureImage = async (val) => {
    if (val && typeof val === "string" && val.startsWith("data:image/")) {
      const compressedImage = await compressImage(val);

      setFormData((prev) => ({ ...prev, IMAGE: compressedImage }));
    } else {
      setFormData((prev) => ({ ...prev, IMAGE: "" }));
      alert(
        "Please capture a valid image or ensure your camera is working."
      );
    }
  };

  const compressImage = (imageDataUrl) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageDataUrl;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = 400;
        const scaleSize = MAX_WIDTH / img.width;
        canvas.width = MAX_WIDTH;
        canvas.height = img.height * scaleSize;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        const compressedImage = canvas.toDataURL("image/jpeg");

        resolve(compressedImage);
      };
    });
  };

  return (
    <div className="bg-blue py-4">
      <Loader open={loader } />
      <p className="text-white underline underline-offset-4 text-2xl">
        Please provide rejected Documents{" "}
      </p>
      <div>
        {Arr &&
          Arr.map((item, idx) => (
            <div key={Arr[idx]} className="flex items-center p-4 text-white">
              <p className="w-1/2 text-center text-white sm:text-xs md:text-xl">
                {Arr[idx]}
              </p>
              <div className="w-1/2 flex justify-start">
                {Arr[idx] === "IMAGE" ? (
                  <div>
                    {!formData.IMAGE && (
                      <RButton
                        buttonName="Enable Camera"
                        handleButtonClick={() => setImageCapture(true)}
                        bgColor="text-black"
                        color
                        border
                        text
                        externalClassName="bg-white"
                      />
                    )}
                    {formData.IMAGE && (
                      <>
                        <div>
                          <img
                            className="border-2 w-[250px] h-[200] rounded-lg"
                            src={formData.IMAGE}
                            alt=""
                          />
                        </div>
                        <div className="mt-2">
                          <button
                            className="text-white"
                            onClick={() => cancelImage("IMAGE")}
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    )}
                    <Modal
                      open={imageCapture}
                      onClose={() => setImageCapture(false)}
                    >
                      <div className="flex justify-start">
                        <CameraCapture
                          handleCaptureImage={handleCaptureImage}
                          imageValue={formData.IMAGE}
                          onSave={() => setImageCapture(false)}
                        />
                      </div>
                    </Modal>
                  </div>
                ) : (
                  <input
                    type="file"
                    name={Arr[idx]}
                    onChange={onChange}
                    accept=".pdf,image/*"
                  />
                )}
              </div>
            </div>
          ))}
      </div>
      {submit && (
        <>
        <div
           className="relative text-center flex flex-col border-2 border-white sm:w-[90%] md:w-[60%]"
           style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "white",
              backgroundColor: "#212F66",
              padding: "0.5rem",
              borderRadius: "2rem",
              boxShadow:
               "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
           }}
        >
          Your Rejection is Submitted
          </div>
      </>
      )}
      <button
        className="bg-red rounded hover:bg-white hover:transition-2sec  px-3 py-2 text-white"
        onClick={handleSendData}
      >
        Submit
      </button>
      <div className="flex justify-center m-auto"></div>
    </div>
  );
};

export default Rejected;
