import React from 'react'

export default function Pincode(props) {
    const handleAddressChange = (event) => {
        props.handleAddressChange(
          "addressDto." + event.target.name,
          event.target.value
        );
      };
  return (
    <>
    <div className="p-2 flex flex-col">
        <label htmlFor="pincode" className="font-semibold">
          Pincode*
        </label>
        <input
          type="text"
          id="pincode"
          name="pincode"
          value={props.nominee.addressDto.pincode}
          // placeholder="Enter Pincode"
          onChange={handleAddressChange}
          // className="mt-2 p-2 bg-red border border-white rounded-xl text-white"
          className="mt-2 p-2 bg-white rounded-xl text-black"
        />
      </div>
    </>
  )
}

 